import React, { useEffect, useState } from 'react';

const SponsorCarousel = () => {
  const [position, setPosition] = useState(0);
  
  const sponsors = [
    { id: 1, alt: "Sponsor 1" },
    { id: 2, alt: "Sponsor 2" },
    { id: 3, alt: "Sponsor 3" },
    { id: 4, alt: "Sponsor 4" },
    { id: 5, alt: "Sponsor 5" },
    { id: 6, alt: "Sponsor 6" },
    { id: 7, alt: "Sponsor 7" },
    { id: 8, alt: "Sponsor 8" },
    { id: 9, alt: "Sponsor 9" },
    { id: 10, alt: "Sponsor 10" },
    { id: 11, alt: "Sponsor 11" },
    { id: 12, alt: "Sponsor 12" },
    { id: 13, alt: "Sponsor 13" },
    { id: 14, alt: "Sponsor 14" }
  ];

  // Create three copies to ensure smooth infinite scroll
  const extendedSponsors = [...sponsors, ...sponsors, ...sponsors];

  useEffect(() => {
    const animation = setInterval(() => {
      setPosition((prev) => {
        // Calculate the width of one complete set of sponsors
        const slideWidth = -(100 * sponsors.length / 2);
        
        // Reset position when reaching end of middle set
        if (prev <= slideWidth) {
          return 0;
        }
        return prev - 0.1; // Reduced speed for smoother animation
      });
    }, 20);

    return () => clearInterval(animation);
  }, [sponsors.length]);

  return (
    <div className="w-full bg-white py-12 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Our Sponsors
        </h2>
        
        <div className="relative overflow-hidden">
          <div 
            className="flex items-center"
            style={{ 
              transform: `translateX(${position}%)`,
              transition: 'transform 0.5s linear'
            }}
          >
            {extendedSponsors.map((sponsor, index) => (
              <div 
                key={`${sponsor.id}-${index}`}
                className="flex-shrink-0 w-32 mx-4 md:w-40 md:mx-6 lg:w-48 lg:mx-8"
              >
                <div className="group relative transition-transform duration-300 hover:scale-110">
                  <img
                    src={`sponsors/${sponsor.id}.png`}
                    alt={sponsor.alt}
                    className="w-full h-auto object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorCarousel;