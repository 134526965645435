import HeroSection from "./Sections/Hero1";
import GlobalEdgeCard from "./Sections/Hero2";
import Hero3 from "./Sections/Hero3";
import Hero4 from "./Sections/Hero4";
import Hero5 from "./Sections/Hero5";
import Hero6 from "./Sections/Hero6";
import Hero7 from "./Sections/Hero7";
import Hero8 from "./Sections/Hero8";
import Hero9 from "./Sections/Hero9";
import Hero10 from "./Sections/Hero10";
import Hero11 from "./Sections/Hero11";
import Hero12 from "./Sections/Hero12";
import Hero13 from "./Sections/Hero13";
import Hero14 from "./Sections/Hero14";
import Hero15 from "./Sections/Hero15";
import Nav from "./Components/Nav";
import Sponsors from "./Sections/Sponsors";


function App() {
  return (
    <div>
       <Nav />
        <GlobalEdgeCard />
        <Sponsors />
        <Hero15 />  {/* motive */}
        <Hero5 /> {/* why */}
        <Hero10 /> {/* lamp */}
        <Hero7 />   {/* our process */}
        <Hero9 />
        <Hero4 />
        <Hero11 />

        <HeroSection />
        <Hero14 /> {/* previous winner */}
        <Hero13 /> {/* previous winner */}

        <Hero3 />  {/* previous award winner */}
        <Hero6 />  {/* footer */}
    </div>
  );
}

export default App;
